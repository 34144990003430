<div class="container-fluid visit-store"
    *ngIf="config.title != 'Deera' && config.title != 'ShubhCards' && config.title != 'BestGifts' && config.title !== 'MayanNew'">
    <ul>
        <li>
            <h3>Visit our latest products</h3>
        </li>
        <li>
            <a class="btn btn-secondary" (click)="getLatestProducts()">Know More &nbsp; <i
                    class="fa-angle-right fa"></i></a>
        </li>
    </ul>
</div>
<div class="container-fluid visit-store" *ngIf="config.title === 'BestGifts'">
    <ul>
        <li>
            <h3>Visit our latest products</h3>
        </li>
        <li>
            <a class="btn btn-secondary" (click)="productsListTazTest()">Know More &nbsp; <i
                    class="fa-angle-right fa"></i></a>
        </li>
    </ul>
</div>
<footer class="container-fluid footer footer_bgcolor" *ngIf="config.title != 'ShubhCards' && config.title != 'MayanNew'">
    <div class="container ">
        <div class="footer-links footer-row-one">
            <div class="footer-col" *ngIf="config.title === 'Deera'">


                <img src="assets/images/deera/Deera-Logo-reverse.png" style="width: 50%;">

            </div>

            <div class="footer-col contact-section address" *ngIf="config.title == 'Deera'">
                <h4 class="sm-bold" style="color: white;">CONTACT US</h4>
                <p style="color: #a1afca !important;"><i class="fa-map-marker fa"
                        style="color: #a1afca !important;"></i>
                    Panduranga Enterprises - Import & Trading - NO.84/1, 4th Floor, 3rd Cross, New Timber Yard Layout,
                    Bengaluru<br>
                    Karanataka - 560026
                    <br>

                </p>
                <!-- <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-44-42327190</a>
                </p> -->
                <p><i class="fa fa-mobile" style="color: #a1afca !important;"></i>
                    <a style="color: #a1afca !important;font-weight:450">8310065761</a>
                </p>
                <p><i class="fa-globe  fa" style="color: #a1afca !important;"></i>
                    <a style="color: #a1afca !important;font-weight:450" target="_blank">deera2021@gmail.com</a>
                </p>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;"
                    *ngIf="config.title != 'Karupattiraja'&& config.title != 'Carience' && config.title != 'Deera'">
                    ONLINE SHOPPING</h4>
                <h4 class="sm-bold" style="color: white;" *ngIf="config.title === 'Deera'">ONLINE SHOPPING</h4>
                <h4 class="sm-bold" style="color: #212529;" *ngIf="config.title == 'Karupattiraja'">OUR CATEGORIES</h4>
                <h4 class="sm-bold" style="color: #212529;" *ngIf="config.title == 'Carience'">POPULAR CATEGORIES</h4>
                <ul *ngIf="config.title != 'BestGifts' && config.title != 'Deera' && config.title != 'Karupattiraja'">
                    <li *ngFor="let online of onLineShopping;" (click)="navigateToProduct(online.name)"><a
                            style="color: #375673 !important;font-weight:450" class="custom-point">{{online.name}} </a>
                    </li>

                </ul>
                <ul *ngIf="config.title === 'Karupattiraja'">
                    <li *ngFor="let online of onLineShopping;" (click)="navigateToProduct(online.name)"><a
                            style="color: #375673 !important;font-weight:450" class="custom-point">{{online.name}}</a>
                    </li>
                </ul>
                <ul *ngIf="config.title === 'Deera'">
                    <li *ngFor="let online of onLineShopping;" (click)="productsListTazTest1(online.name)"><a
                            style="color: #a1afca !important;font-weight:450" class="custom-point">{{online.name}}</a>
                    </li>
                </ul>
                <ul *ngIf="config.title === 'BestGifts'">
                    <li *ngFor="let online of onLineShopping;" (click)="productsListTazTest1(online.name)"><a
                            style="color: #375673 !important;font-weight:450" class="custom-point">{{online.name}}</a>
                    </li>
                </ul>
            </div>
            <div class="footer-col" *ngIf="config.title !== 'Deera'">
                <h4 class="sm-bold" style="color: #212529;">QUICK LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" (click)="windowscroll()"
                            routerLink="/">Home</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="about-us">About Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="contact-us"
                            *ngIf="config.title == 'ShubhCards'  || config.title == 'BestGifts'||config.title == 'Kubendran'||config.title == 'Carience'">Contact
                            Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="services"
                            *ngIf="config.title != 'ShubhCards'  && config.title != 'BestGifts'&& config.title != 'Kubendran'&& config.title != 'Carience'&& config.title != 'Karupattiraja'">Services</a>
                    </li>
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="footer-col" *ngIf="config.title === 'Deera'">
                <h4 class="sm-bold" style="color: white;">QUICK LINKS</h4>
                <ul>
                    <li><a style="color: #a1afca !important;font-weight:450" (click)="windowscroll()"
                            routerLink="/">Home</a></li>
                    <li><a style="color: #a1afca !important;font-weight:450" routerLink="about-us">About Us</a></li>
                    <li><a style="color: #a1afca !important;font-weight:450" routerLink="contact-us">Contact Us</a></li>

                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="footer-col" *ngIf="config.title !== 'Deera'">
                <h4 class="sm-bold" style="color: #212529;">USEFUL LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="contact-us"
                            *ngIf="config.title != 'ShubhCards'  && config.title != 'BestGifts' && config.title != 'Carience'">Contact
                            Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/FAQs"
                            *ngIf=" config.title != 'BestGifts' && config.title != 'Carience'">FAQ</a></li>
                    <li><a style="color: #375673 !important;font-weight:450"
                            routerLink="/terms-and-condition">T&amp;C</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/privacy-policy">Privacy
                            Policy</a></li>
                    <li *ngIf="config.title != 'Carience'"><a style="color: #375673 !important;font-weight:450"
                            routerLink="/cancellation-policy">Cancellation Policy</a></li>
                    <li *ngIf="config.title != 'Carience'"><a style="color: #375673 !important;font-weight:450"
                            routerLink="/shipping-and-delivery">Shipping & Delivery</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/return-policy"
                            *ngIf="config.title == 'ShubhCards'|| config.title == 'BestGifts'">Return & Exchanges</a>
                    </li>
                </ul>
            </div>
            <div class="footer-col" *ngIf="config.title === 'Deera'">
                <h4 class="sm-bold" style="color: white;">USEFUL LINKS</h4>
                <ul>
                    <!-- <li><a style="color: #a1afca !important;font-weight:450" routerLink="contact-us" >Contact Us</a></li> -->
                    <li><a style="color: #a1afca !important;font-weight:450" routerLink="/FAQs">FAQ</a></li>
                    <li><a style="color: #a1afca !important;font-weight:450"
                            routerLink="/terms-and-condition">T&amp;C</a></li>
                    <li><a style="color: #a1afca !important;font-weight:450" routerLink="/privacy-policy">Privacy
                            Policy</a></li>
                    <li><a style="color: #a1afca !important;font-weight:450"
                            routerLink="/cancellation-policy">Cancellation Policy</a></li>
                    <li><a style="color: #a1afca !important;font-weight:450"
                            routerLink="/shipping-and-delivery">Shipping & Delivery</a></li>
                    <li><a style="color: #a1afca !important;font-weight:450" routerLink="/return-policy">Return &
                            Exchanges</a></li>
                </ul>
            </div>
            <div class="footer-col" *ngIf="config.title === 'Deera'">
                <h4 class="sm-bold" style="color: white;">SOCIAL MEDIA</h4>
                <ul class="social-icons" *ngIf="config.title == 'Deera' " style="display: flex;    margin-left: -5%;">
                    <li>
                        <a href="https://www.facebook.com/shopatdeera" style="padding: 6px 12px 6px 11px;
                        background: #a1afca;
                        border-radius: 50%;
                        margin-left: 10px;">
                            <i class="fa fa-facebook" style="color: #0c377b;"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/i/flow/login" style="padding: 6px 10px 6px 9px;
                        background: #a1afca;
                        border-radius: 50%;
                        margin-left: 10px;">
                            <i class="fa fa-twitter" style="color: #0c377b;"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://in.linkedin.com" style="padding: 6px 10px 6px 9px;
                        background: #a1afca;
                        border-radius: 50%;
                        margin-left: 10px;">
                            <i class="fa fa-linkedin" style="color: #0c377b;"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/shopatdeera/?hl=en" style="padding: 6px 10px 6px 9px;
                        background: #a1afca;
                        border-radius: 50%;
                        margin-left: 10px;">
                            <i class="fa fa-instagram" aria-hidden="true" style="color: #0c377b;"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" style="padding: 6px 10px 6px 9px;
                        background: #a1afca;
                        border-radius: 50%;
                        margin-left: 10px;">
                            <i class="fa fa-pinterest" aria-hidden="true" style="color: #0c377b;"></i>
                        </a>
                    </li>

                </ul>
            </div>
            <div class="footer-col contact-section address" *ngIf="config.title == 'LuckMe'">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    B S M Complex, Revolution Av,<br>
                    Victoria, Mahe
                    <br>

                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+248 251 3732</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" href="sales@supersoma.sc"
                        target="_blank">sales@supersoma.sc</a>
                </p>
            </div>


            <!-- <div class="footer-col contact-section address" *ngIf = "config.title == 'ShubhCards'" >

            
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    New #16,Subramaniam Street, Abhiramapuram,Chennai,<br>
                    Tamil Nadu 600018
                    <br>
                    
                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-44-42327190</a>
                </p>
                <p><i class="fa fa-mobile"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-98400 49389</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450"  target="_blank">shubhcards@gmail.com</a>
                </p>
            </div> -->



            <div class="footer-col contact-section address" *ngIf="config.title == 'Carience'">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    <!-- 4/253-8 Ambasamudram Road, Idaikal, Tirunelveli, <br>
                    Tamil Nadu, 627602 India
                    <br> -->
                    {{address1}} {{address.city}},<br>

                    {{address.state}}-{{address.zipcode}}

                </p>

                <!-- <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-44-42327190</a>
                </p> -->
                <p><i class="fa fa-mobile"></i>
                    <a style="color: #375673 !important;font-weight:450">{{phone}}</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" target="_blank">{{email}}</a>
                </p>
            </div>

            <div class="footer-col contact-section address" *ngIf="config.title == 'BestGifts'">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    BEST PHOTO STUDIO AND CUSTOMIZED GIFTS <br>
                    53/32,THIRD FLOOR ELITE TOWERS,
                    UPSTAIRS TO INDIAN BANK,<br>
                    NORTH USMAN ROAD,
                    T. NAGAR, <br>
                    CHENNAI - 600017<br>
                    <br>

                </p>
                <!-- <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-44-42327190</a>
                </p> -->
                <p><i class="fa fa-mobile"></i>
                    <a style="color: #375673 !important;font-weight:450">044 - 48677890,+919445909988</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" target="_blank">chennaibeststudio@gmail.com</a>
                </p>
            </div>
            <div class="footer-col contact-section address" *ngIf="config.title == 'Kubendran'">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    Kubendran<br>


                </p>
                <!-- <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-44-42327190</a>
                </p> -->
                <p><i class="fa fa-mobile"></i>
                    <a style="color: #375673 !important;font-weight:450">&nbsp;8072616729</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" target="_blank">kubendran@gmail.com </a>
                </p>
            </div>
            <div class="footer-col contact-section address" *ngIf="config.title == 'Karupattiraja'">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    Karupatti Raja<br>
                    {{address1}},<br>
                    {{address.city}},
                    {{address.state}}-{{address.zipcode}}
                </p>

                <!-- <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-44-42327190</a>
                </p> -->
                <p><i class="fa fa-mobile"></i>
                    <a style="color: #375673 !important;font-weight:450">{{phone}}</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" target="_blank">{{email}}</a>
                </p>
            </div>
        </div>
    </div>
</footer>

<footer class="container-fluid footer footer_bgcolor shubhcards"
    *ngIf="config.title !== 'Deera' && config.title !== 'Karupattiraja' && config.title !== 'BestGifts' && config.title !== 'MayanNew'">
    <div class="container ">
        <div class="footer-links footer-row-one">
            <div class="footer-col Shubhcards" *ngIf="config.title === 'ShubhCards'">
                <img src="../../../../../assets/images/ShubhCards/1650351006565 - Logo 1-fotor-20240502182428.png"
                    style="width: 100%;">
                <div>
                    <div class="footer-content-Shubhcards" style="color:whitesmoke !important;">
                        Shubh Cards, a brand of Futuristic Print Vision Pvt Ltd, having its base at Chennai, India,
                        was established in the year 2004 with the goal of designing and creating personalized wedding
                        cards.
                        At a time when standard, off-the-shelf wedding cards was the norm, Shubh Cards revolutionized
                        the market with their pioneering idea of custom-made wedding cards.
                        Shubh Cards has succeeded in making hundreds of our customers extremely happy and
                        satisfied not only all over India but also worldwide. Since then, we have designed and created
                        over thousands of wedding cards
                        and accessories.

                    </div>
                </div>
            </div>
            <div class="footer-col" *ngIf="config.title === 'ShubhCards'" style="margin-left: 7rem;">
                <h4 class="sm-bold" style="color: white; font-size: 20px !important;">MENUS</h4>
                <ul style="color:whitesmoke !important;">
                    <li style="cursor: pointer;" (click)="windowscroll()" routerLink="/">Home</li>
                    <li style="cursor: pointer;" (click)="productsListTazTest()">Products</li>
                    <li style="cursor: pointer;" (click)="productListing()">Themes</li>
                    <li style="cursor: pointer;" routerLink="about-us">About Us</li>
                    <li style="cursor: pointer;" routerLink="contact-us">Contact Us</li>
                </ul>
            </div>

            <div class="footer-col" *ngIf="config.title === 'ShubhCards'" style="margin-left:3.5rem;">
                <h4 class="sm-bold" style="color: white;  font-size: 20px !important;">CATEGORIES</h4>
                <ul style="color:whitesmoke !important;">
                    <li style="cursor: pointer;" *ngFor="let online of onLineShopping;"
                        (click)="productsListTazTest1(online.name)">{{online.name}}</li>


                </ul>
            </div>

            <!-- <div class="footer-col" *ngIf = "config.title === 'ShubhCards'" style="margin-left:3rem;width: 13%;">
                <h4 class="sm-bold" style="color: white; font-size: 20px !important;">THEMES</h4>
                <ul style="color:whitesmoke !important;">
                    <li (click)="navigateToProductTheme('Arts of India')" style="cursor: pointer;">Arts of India</li>
                    <li (click)="navigateToProductTheme('Floral')" style="cursor: pointer;">Floral</li>
                    <li (click)="navigateToProductTheme('Indian Patterns')" style="cursor: pointer;">Indian Patterns</li>
                    <li (click)="navigateToProductTheme('Laser Cut')" style="cursor: pointer;">Laser Cut</li>
                    <li (click)="navigateToProductTheme('Lotus')" style="cursor: pointer;">Lotus</li>
                    <li (click)="navigateToProductTheme('Peacock')" style="cursor: pointer;">Peacock</li>
                    <li (click)="navigateToProductTheme('Traditional')" style="cursor: pointer;">Traditional</li>
                    <li (click)="navigateToProductTheme('Tree of Life')" style="cursor: pointer;">Tree of Life</li>
                    
                </ul>
            </div> -->

            <div class="footer-col" *ngIf="config.title !== 'Deera'" style="margin-left:3rem;">
                <h4 class="sm-bold" style="color: #ffffff; font-size: 20px !important;">USEFUL LINKS</h4>
                <ul style="color:whitesmoke !important;">
                    <li><a style="color:whitesmoke !important;font-weight:450" routerLink="contact-us"
                            *ngIf="config.title != 'ShubhCards'  && config.title != 'BestGifts' && config.title != 'Carience'">Contact
                            Us</a></li>
                    <li><a style="color:whitesmoke !important;font-weight:450" routerLink="/FAQs"
                            *ngIf=" config.title != 'BestGifts' && config.title != 'Carience'">FAQ</a></li>
                    <li><a style="color:whitesmoke !important;font-weight:450"
                            routerLink="/terms-and-condition">T&amp;C</a></li>
                    <li><a style="color:whitesmoke !important;font-weight:450" routerLink="/privacy-policy">Privacy
                            Policy</a></li>
                    <li *ngIf="config.title != 'Carience'"><a style="color:whitesmoke !important;font-weight:450"
                            routerLink="/cancellation-policy">Cancellation Policy</a></li>
                    <li *ngIf="config.title != 'Carience'"><a style="color:whitesmoke !important;font-weight:450"
                            routerLink="/shipping-and-delivery">Shipping & Delivery</a></li>
                    <li><a style="color:whitesmoke !important;font-weight:450" routerLink="/return-policy"
                            *ngIf="config.title == 'ShubhCards'|| config.title == 'BestGifts'">Return & Exchanges</a>
                    </li>
                </ul>
            </div>

            <div>
                <div class="footer-col contact-section address" *ngIf="config.title == 'ShubhCards'"
                    style="margin-left:3rem;">
                    <h4 class="sm-bold" style="color: #ffffff; font-size: 20px !important;">CONTACT US</h4>
                    <p style="color:whitesmoke !important;"><i class="fa-map-marker fa"></i>
                        New #16,Subramaniam Street,
                        &nbsp;&nbsp;&nbsp;&nbsp;Abhiramapuram,Chennai,<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;Tamil Nadu 600 018
                        <br>

                    </p>
                    <br>
                    <p><i class="fa-phone  fa"></i>
                        <a style="color:whitesmoke !important;font-weight:450">+91-44-42327190 <br> &nbsp;&nbsp;&nbsp;
                            +91-98400 49389</a>
                    </p>
                    <!-- <p><i class="fa fa-mobile"></i>
                    <a style="color: #ffffff !important;font-weight:450"></a>
                </p> -->
                    <br>
                    <p><i class="fa-envelope  fa"></i>
                        <a style="color:whitesmoke !important;font-weight:450" target="_blank">shubhcards@gmail.com</a>
                    </p>
                </div>
                <div>
                    <ul class="social-icons">
                        <li>
                            <div class="social-icon-circle">
                                <a href="https://www.facebook.com/login">
                                    <i class="fa fa-facebook fa-inverse icon"></i>
                                </a>
                            </div>
                        </li>
                        <li>
                            <div class="social-icon-circle">
                                <a href="https://twitter.com/i/flow/login">
                                    <i class="fa fa-twitter fa-inverse icon"></i>
                                </a>
                            </div>
                        </li>
                        <!-- <li>
                        <div class="social-icon-circle">
                            <a href="#">
                                <i class="fa fa-pinterest fa-inverse" aria-hidden="true"></i>
                            </a>
                        </div>
                    </li> -->
                        <li>
                            <div class="social-icon-circle">
                                <a href="https://in.linkedin.com">
                                    <i class="fa fa-instagram fa-inverse icon"></i>
                                </a>
                            </div>
                        </li>
                        <!-- <li>
                        <div class="social-icon-circle">
                            <a href="#">
                                <i class="fa fa-youtube-play fa-inverse" aria-hidden="true"></i>
                            </a>
                        </div>
                    </li> -->

                    </ul>
                </div>
            </div>
            <div class="footer-col contact-section address" *ngIf="config.title == 'Deera'">
                <h4 class="sm-bold" style="color: white;">CONTACT US</h4>
                <p style="color: #a1afca !important;"><i class="fa-map-marker fa"
                        style="color: #a1afca !important;"></i>
                    Panduranga Enterprises - Import & Trading - NO.84/1, 4th Floor, 3rd Cross, New Timber Yard Layout,
                    Bengaluru<br>
                    Karanataka - 560026
                    <br>

                </p>
                <!-- <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-44-42327190</a>
                </p> -->
                <p><i class="fa fa-mobile" style="color: #a1afca !important;"></i>
                    <a style="color: #a1afca !important;font-weight:450">8310065761</a>
                </p>
                <p><i class="fa-globe  fa" style="color: #a1afca !important;"></i>
                    <a style="color: #a1afca !important;font-weight:450" target="_blank">deera2021@gmail.com</a>
                </p>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;"
                    *ngIf="config.title != 'Karupattiraja'&& config.title != 'Carience' && config.title != 'Deera' && config.title != 'ShubhCards'">
                    ONLINE SHOPPING</h4>
                <h4 class="sm-bold" style="color: white;" *ngIf="config.title === 'Deera'">ONLINE SHOPPING</h4>
                <h4 class="sm-bold" style="color: #212529;" *ngIf="config.title == 'Karupattiraja'">OUR CATEGORIES</h4>
                <h4 class="sm-bold" style="color: #212529;" *ngIf="config.title == 'Carience'">POPULAR CATEGORIES</h4>
                <ul
                    *ngIf="config.title != 'BestGifts' && config.title != 'Deera' && config.title != 'Karupattiraja' && config.title != 'ShubhCards'">
                    <li *ngFor="let online of onLineShopping;" (click)="navigateToProduct(online.name)"><a
                            style="color: #375673 !important;font-weight:450" class="custom-point">{{online.name}} </a>
                    </li>

                </ul>
                <ul *ngIf="config.title === 'Karupattiraja'">
                    <li *ngFor="let online of onLineShopping;" (click)="navigateToProduct(online.name)"><a
                            style="color: #375673 !important;font-weight:450" class="custom-point">{{online.name}}</a>
                    </li>
                </ul>
                <ul *ngIf="config.title === 'Deera'">
                    <li *ngFor="let online of onLineShopping;" (click)="navigateToProduct(online.name)"><a
                            style="color: #a1afca !important;font-weight:450" class="custom-point">{{online.name}}</a>
                    </li>
                </ul>
                <ul *ngIf="config.title === 'BestGifts'">
                    <li *ngFor="let online of onLineShopping;" (click)="navigateToProduct(online.name)"><a
                            style="color: #375673 !important;font-weight:450" class="custom-point">{{online.name}}</a>
                    </li>
                </ul>
            </div>
            <div class="footer-col" *ngIf="config.title !== 'Deera' && config.title !== 'ShubhCards'">
                <h4 class="sm-bold" style="color: #212529;">QUICK LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" (click)="windowscroll()"
                            routerLink="/">Home</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="about-us">About Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="contact-us"
                            *ngIf="config.title == 'ShubhCards'  || config.title == 'BestGifts'||config.title == 'Kubendran'||config.title == 'Carience'">Contact
                            Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="services"
                            *ngIf="config.title != 'ShubhCards'  && config.title != 'BestGifts'&& config.title != 'Kubendran'&& config.title != 'Carience'&& config.title != 'Karupattiraja'">Services</a>
                    </li>
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="footer-col" *ngIf="config.title === 'Deera'">
                <h4 class="sm-bold" style="color: white;">QUICK LINKS</h4>
                <ul>
                    <li><a style="color: #a1afca !important;font-weight:450" (click)="windowscroll()"
                            routerLink="/">Home</a></li>
                    <li><a style="color: #a1afca !important;font-weight:450" routerLink="about-us">About Us</a></li>
                    <li><a style="color: #a1afca !important;font-weight:450" routerLink="contact-us">Contact Us</a></li>

                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>

            <div class="footer-col" *ngIf="config.title === 'Deera'">
                <h4 class="sm-bold" style="color: white;">USEFUL LINKS</h4>
                <ul>
                    <!-- <li><a style="color: #a1afca !important;font-weight:450" routerLink="contact-us" >Contact Us</a></li> -->
                    <li><a style="color: #a1afca !important;font-weight:450" routerLink="/FAQs">FAQ</a></li>
                    <li><a style="color: #a1afca !important;font-weight:450"
                            routerLink="/terms-and-condition">T&amp;C</a></li>
                    <li><a style="color: #a1afca !important;font-weight:450" routerLink="/privacy-policy">Privacy
                            Policy</a></li>
                    <li><a style="color: #a1afca !important;font-weight:450"
                            routerLink="/cancellation-policy">Cancellation Policy</a></li>
                    <li><a style="color: #a1afca !important;font-weight:450"
                            routerLink="/shipping-and-delivery">Shipping & Delivery</a></li>
                    <li><a style="color: #a1afca !important;font-weight:450" routerLink="/return-policy">Return &
                            Exchanges</a></li>
                </ul>
            </div>
            <div class="footer-col" *ngIf="config.title === 'Deera'">
                <h4 class="sm-bold" style="color: white;">SOCIAL MEDIA</h4>
                <ul class="social-icons" *ngIf="config.title == 'Deera' " style="display: flex;    margin-left: -5%;">
                    <li>
                        <a href="https://www.facebook.com/shopatdeera" style="padding: 6px 12px 6px 11px;
                        background: #a1afca;
                        border-radius: 50%;
                        margin-left: 10px;">
                            <i class="fa fa-facebook" style="color: #0c377b;"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/i/flow/login" style="padding: 6px 10px 6px 9px;
                        background: #a1afca;
                        border-radius: 50%;
                        margin-left: 10px;">
                            <i class="fa fa-twitter" style="color: #0c377b;"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://in.linkedin.com" style="padding: 6px 10px 6px 9px;
                        background: #a1afca;
                        border-radius: 50%;
                        margin-left: 10px;">
                            <i class="fa fa-linkedin" style="color: #0c377b;"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/shopatdeera/?hl=en" style="padding: 6px 10px 6px 9px;
                        background: #a1afca;
                        border-radius: 50%;
                        margin-left: 10px;">
                            <i class="fa fa-instagram" aria-hidden="true" style="color: #0c377b;"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" style="padding: 6px 10px 6px 9px;
                        background: #a1afca;
                        border-radius: 50%;
                        margin-left: 10px;">
                            <i class="fa fa-pinterest" aria-hidden="true" style="color: #0c377b;"></i>
                        </a>
                    </li>

                </ul>
            </div>
            <div class="footer-col contact-section address" *ngIf="config.title == 'LuckMe'">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    B S M Complex, Revolution Av,<br>
                    Victoria, Mahe
                    <br>

                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+248 251 3732</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" href="sales@supersoma.sc"
                        target="_blank">sales@supersoma.sc</a>
                </p>
            </div>





            <div class="footer-col contact-section address" *ngIf="config.title == 'Carience'">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    <!-- 4/253-8 Ambasamudram Road, Idaikal, Tirunelveli, <br>
                    Tamil Nadu, 627602 India
                    <br> -->
                    {{address1}} {{address.city}},<br>

                    {{address.state}}-{{address.zipcode}}

                </p>

                <!-- <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-44-42327190</a>
                </p> -->
                <p><i class="fa fa-mobile"></i>
                    <a style="color: #375673 !important;font-weight:450">{{phone}}</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" target="_blank">{{email}}</a>
                </p>
            </div>

            <div class="footer-col contact-section address" *ngIf="config.title == 'BestGifts'">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    BEST PHOTO STUDIO AND CUSTOMIZED GIFTS <br>
                    53/32,THIRD FLOOR ELITE TOWERS,
                    UPSTAIRS TO INDIAN BANK,<br>
                    NORTH USMAN ROAD,
                    T. NAGAR, <br>
                    CHENNAI - 600017<br>
                    <br>

                </p>
                <!-- <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-44-42327190</a>
                </p> -->
                <p><i class="fa fa-mobile"></i>
                    <a style="color: #375673 !important;font-weight:450">044 - 48677890,+919445909988</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" target="_blank">chennaibeststudio@gmail.com</a>
                </p>
            </div>
            <div class="footer-col contact-section address" *ngIf="config.title == 'Kubendran'">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    Kubendran<br>


                </p>
                <!-- <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-44-42327190</a>
                </p> -->
                <p><i class="fa fa-mobile"></i>
                    <a style="color: #375673 !important;font-weight:450">&nbsp;8072616729</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" target="_blank">kubendran@gmail.com </a>
                </p>
            </div>
            <div class="footer-col contact-section address" *ngIf="config.title == 'Karupattiraja'">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    Karupatti Raja<br>
                    {{address1}},<br>
                    {{address.city}},
                    {{address.state}}-{{address.zipcode}}
                </p>

                <!-- <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-44-42327190</a>
                </p> -->
                <p><i class="fa fa-mobile"></i>
                    <a style="color: #375673 !important;font-weight:450">{{phone}}</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" target="_blank">{{email}}</a>
                </p>
            </div>
        </div>
    </div>
</footer>

<!-- Mayan New Footer Two -->
<footer *ngIf="config.title=='MayanNew'" class='mayannew-footer'>
    <div class="mayannew-container-fluid-footer-two mayannew-sub-footer">
        <!-- Logo Section -->
        <div class="footer-logo">
            <img src="../../../../../assets/images/mayannew/Mayan Logo Footer.png" alt="Mayan Handicrafts Logo" class="logo"/>
        </div>

        <!-- Mayan Handicrafts Section -->
        <div class="footer-mayan-handicrafts">
            <h2 class="footer-title tertiary-bold">MAYAN HANDICRAFTS</h2>
            <ul class="footer-links tertiary-book">
                <li><a routerLink="/"class="footer-link">Home</a></li>
                <li><a routerLink="about-us" class="footer-link">About</a></li>
                <li><a routerLink="/product" class="footer-link">Shop</a></li>
                <li><a routerLink="/terms-and-condition" class="footer-link">Terms and Conditions</a></li>
                <li><a routerLink="/shipping-and-delivery" class="footer-link">Shipping Policy</a></li>
                <li><a routerLink="/return-policy" class="footer-link">Return and Refund Policy</a></li>
            </ul>
        </div>

        <!-- Help Section -->
        <div class="footer-help">
            <h2 class="footer-title tertiary-bold">HELP</h2>
            <ul class="footer-links tertiary-book">
                <li><a routerLink="/FAQs" class="footer-link">Frequently Asked Questions</a></li>
                <li><a href="#" class="footer-link">Returns</a></li>
                <li><a routerLink="/cancellation-policy" class="footer-link">Cancellations</a></li>
                <li><a href="#" class="footer-link">Track Your Order</a></li>
                <li><a href="#" class="footer-link">Customer Care</a></li>
                <li><a href="#" class="footer-link">How Do I Redeem My Coupon</a></li>
            </ul>
        </div>

        <!-- Shop By Section -->
        <div class="footer-shop-by">
            <h2 class="footer-title tertiary-bold">SHOP BY</h2>
            <ul class="footer-links tertiary-book">
                <li (click)="getProductByFilter_mayan('Pillars&architecture')" class="footer-link" style="cursor: pointer" >Pillars & Architecture</li>
                <li (click)="getProductByFilter_mayan('Naturals&Portraits')" class="footer-link" style="cursor: pointer">Natural & Portraits</li>
                <li (click)="getProductByFilter_mayan('Kitchen household')" class="footer-link" style="cursor: pointer">Kitchen Household</li>
                <li (click)="getProductByFilter_mayan('Garden')" class="footer-link" style="cursor: pointer">Garden</li>
                <li (click)="getProductByFilter_mayan('Divine')" class="footer-link" style="cursor: pointer">Divine</li>
                <li (click)="getProductByFilter_mayan('Decor')" class="footer-link" style="cursor: pointer">Decor</li>
            </ul>
        </div>

        <!-- Contact Section -->
        <div class="footer-contact">
            <h2 class="footer-title tertiary-bold">CONTACT</h2>
            <p class="footer-text tertiary-book">No 80, Vaniga Vilagam, Five Rahtas,<br> Mahabalipuram, Kanchipuram,<br> Tamil Nadu - 603 104</p>
            <p class="footer-text tertiary-book">+91 9840800390</p>
            <p class="footer-text tertiary-book">mayanrajesh23@gmail.com</p>
            <h2 class="footer-title tertiary-bold social-media-title">SOCIAL MEDIA</h2>
            <div class="social-media">
                <a href="#" class="social-icon"><i class="fa fa-facebook"></i></a>
                <a href="#" class="social-icon"><i class="fa fa-twitter"></i></a>
                <a href="#" class="social-icon"><i class="fa fa-pinterest"></i></a>
                <a href="#" class="social-icon"><i class="fa fa-instagram"></i></a>
                <a href="#" class="social-icon"><i class="fa fa-youtube"></i></a>
            </div>
        </div>
    </div>
</footer>
